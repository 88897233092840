/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/ShipsLayer.jsx

import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import shipIconImage from '../assets/ship-icon.png';

const shipIcon = new L.Icon({
    iconUrl: shipIconImage,
    iconSize: [20, 20], // Reduced size
});

const ShipsLayer = ({ ships, onAssignBerth, highlightedShipId }) => {
    const handleClick = (ship, event) => {
        if (ship.waitingAtPort) {
            onAssignBerth(ship, event.latlng);
        }
    };

    return (
        <>
            {ships.map((ship) => {
                const isHighlighted = ship.id === highlightedShipId;

                return (
                    <Marker
                        key={ship.id}
                        position={ship.position}
                        icon={shipIcon}
                        className={isHighlighted ? 'ship-marker-highlighted' : ''}
                        eventHandlers={{
                            click: (event) => handleClick(ship, event),
                        }}
                    >
                        <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                            <div>
                                <p>
                                    <strong>ID:</strong> {ship.id}
                                </p>
                                <p>
                                    <strong>Type:</strong> {ship.metadata.type}
                                </p>
                                <p>
                                    <strong>Cargo:</strong>{' '}
                                    {ship.metadata.commodity || ship.metadata.type}
                                </p>
                                <p>
                                    <strong>Value:</strong> ${ship.metadata.cargoValue}
                                </p>
                                <p>
                                    <strong>Status:</strong>{' '}
                                    {ship.isDocked
                                        ? 'Docked'
                                        : ship.isMovingToBerth
                                            ? 'Moving to Berth'
                                            : ship.waitingAtPort
                                                ? 'Waiting at Port'
                                                : 'On Move'}
                                </p>
                                {/* Add more details as needed */}
                            </div>
                        </Tooltip>
                    </Marker>
                );
            })}
        </>
    );
};

export default ShipsLayer;
