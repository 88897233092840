// src/utils/greatCircle.js

export function generateGreatCirclePath(startLatLng, endLatLng, numberOfPoints = 100) {
    const [lat1, lon1] = startLatLng.map(toRadians);
    const [lat2, lon2] = endLatLng.map(toRadians);

    const deltaLon = lon2 - lon1;

    const a =
        Math.pow(Math.cos(lat2) * Math.sin(deltaLon), 2) +
        Math.pow(
            Math.cos(lat1) * Math.sin(lat2) -
            Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLon),
            2
        );
    const b =
        Math.sin(lat1) * Math.sin(lat2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.cos(deltaLon);
    const angularDistance = Math.atan2(Math.sqrt(a), b);

    const path = [];
    let crossesAntimeridian = false;
    let crossingPoint = null;

    let prevLonDegrees = null;

    for (let i = 0; i <= numberOfPoints; i++) {
        const f = i / numberOfPoints;
        const sinAngularDistance = Math.sin(angularDistance);
        const A = Math.sin((1 - f) * angularDistance) / sinAngularDistance;
        const B = Math.sin(f * angularDistance) / sinAngularDistance;

        const x = A * Math.cos(lat1) * Math.cos(lon1) + B * Math.cos(lat2) * Math.cos(lon2);
        const y = A * Math.cos(lat1) * Math.sin(lon1) + B * Math.cos(lat2) * Math.sin(lon2);
        const z = A * Math.sin(lat1) + B * Math.sin(lat2);

        const latRadians = Math.atan2(z, Math.sqrt(x * x + y * y));
        const lonRadians = Math.atan2(y, x);

        let latDegrees = toDegrees(latRadians);
        let lonDegrees = toDegrees(lonRadians);

        lonDegrees = ((lonDegrees + 540) % 360) - 180; // Normalize longitude to [-180, 180]

        // Check for antimeridian crossing
        if (prevLonDegrees !== null) {
            const deltaLonDegrees = Math.abs(lonDegrees - prevLonDegrees);
            if (deltaLonDegrees > 180) {
                crossesAntimeridian = true;
                crossingPoint = [latDegrees, lonDegrees];
            }
        }
        prevLonDegrees = lonDegrees;

        path.push([latDegrees, lonDegrees]);
    }

    return { path, crossesAntimeridian, crossingPoint };
}

function toRadians(degrees) {
    return (degrees * Math.PI) / 180;
}

function toDegrees(radians) {
    return (radians * 180) / Math.PI;
}
