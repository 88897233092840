/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/BerthsLayer.jsx

import React from 'react';
import { CircleMarker, Tooltip } from 'react-leaflet';

const BerthsLayer = ({ portBerths, selectedPort, showAllBerths, highlightedBerth }) => {
    if (!selectedPort || !portBerths[selectedPort]) {
        return null;
    }

    const berths = portBerths[selectedPort];

    return (
        <>
            {berths.map((berth, index) => {
                const { name, coordinates } = berth;

                // For simplicity, using the first coordinate as the berth position
                const berthPosition = coordinates[0];

                const isVisible =
                    showAllBerths || (highlightedBerth !== null && highlightedBerth === index);

                if (!isVisible) {
                    return null;
                }

                return (
                    <CircleMarker
                        key={index}
                        center={berthPosition}
                        radius={5}
                        color={highlightedBerth === index ? 'red' : 'green'}
                        fillColor={highlightedBerth === index ? 'red' : 'green'}
                        fillOpacity={0.8}
                    >
                        <Tooltip direction="top" offset={[0, -10]} opacity={1}>
                            <span>{name}</span>
                        </Tooltip>
                    </CircleMarker>
                );
            })}
        </>
    );
};

export default BerthsLayer;
