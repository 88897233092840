/* eslint-disable no-unused-vars */
// src/components/Dashboard.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import PortSelector from './PortSelector';
import PortMap from './PortMap';
import IncomingVessels from './IncomingVessels';
import BerthPlanning from './BerthPlanning';
import NationalShipsTracking from './NationalShipsTracking';
import BusinessMetrics from './BusinessMetrics';
import OperationalMetrics from './OperationalMetrics';
import FinancialMetrics from './FinancialMetrics';
import { ShipDataProvider } from './ShipDataContext';

const Dashboard = () => {
    const navigate = useNavigate();
    const [selectedPort, setSelectedPort] = useState(null);
    const [highlightedShipId, setHighlightedShipId] = useState(null);
    const [shipCount, setShipCount] = useState(200);
    const [showAllBerths, setShowAllBerths] = useState(false);
    const [highlightedBerth, setHighlightedBerth] = useState(null);

    // Define state variables for collapsing panels
    const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(false);
    const [rightPanelCollapsed, setRightPanelCollapsed] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/signin');
    };

    const handleSelectPort = (port) => {
        setSelectedPort(port);
    };

    return (
        <div className="dashboard-container">
            <NavBar handleLogout={handleLogout} />
            <PortSelector onSelectPort={handleSelectPort} />
            <ShipDataProvider shipCount={shipCount}>
                <div className="dashboard-content">
                    {/* Left Floating Card */}
                    <div className={`floating-card left-block ${leftPanelCollapsed ? 'collapsed' : ''}`}>
                        <button
                            className="collapse-button"
                            onClick={() => setLeftPanelCollapsed(!leftPanelCollapsed)}
                        >
                            {leftPanelCollapsed ? '>>' : '<<'}
                        </button>
                        {!leftPanelCollapsed && (
                            <div className="scrollable-list">
                                <IncomingVessels
                                    selectedPort={selectedPort}
                                    setHighlightedShipId={setHighlightedShipId}
                                />
                            </div>
                        )}
                    </div>

                    {/* Main Map Block */}
                    <div className="map-block">
                        <PortMap
                            selectedPort={selectedPort}
                            showAllBerths={showAllBerths}
                            highlightedBerth={highlightedBerth}
                            highlightedShipId={highlightedShipId}
                        />
                    </div>

                    {/* Right Floating Card */}
                    <div className={`floating-card right-block ${rightPanelCollapsed ? 'collapsed' : ''}`}>
                        <button
                            className="collapse-button"
                            onClick={() => setRightPanelCollapsed(!rightPanelCollapsed)}
                        >
                            {rightPanelCollapsed ? '>>' : '<<'}
                        </button>
                        {!rightPanelCollapsed && (
                            <div className="scrollable-list">
                                <BerthPlanning
                                    selectedPort={selectedPort}
                                    showAllBerths={showAllBerths}
                                    setShowAllBerths={setShowAllBerths}
                                    setHighlightedBerth={setHighlightedBerth}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Bottom Metrics */}
                <div className="dashboard-bottom">
                    {/* National Ships Tracking - Full Width */}
                    <div className="national-ships-tracking-wrapper">
                        <NationalShipsTracking selectedPort={selectedPort} />
                    </div>

                    {/* Metrics - BusinessMetrics double width, Operational and Financial single */}
                    <div className="metric-container-wrapper">
                        <div className="metric-double">
                            <BusinessMetrics selectedPort={selectedPort} />
                        </div>
                        <div className="metric-single">
                            <OperationalMetrics selectedPort={selectedPort} />
                        </div>
                        <div className="metric-single">
                            <FinancialMetrics selectedPort={selectedPort} />
                        </div>
                    </div>
                </div>
            </ShipDataProvider>
        </div>
    );
};

export default Dashboard;
