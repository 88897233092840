/* eslint-disable no-unused-vars */
// src/components/SignIn.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';

const SignIn = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');  // Firebase uses email instead of username
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSignIn = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            // Store the user token in localStorage (optional)
            const token = await user.getIdToken();
            localStorage.setItem('token', token);
            navigate('/dashboard');
        } catch (err) {
            console.error('Login error:', err);
            setError('Login failed. Please check your credentials.');
        }
    };

    return (
        <div className="signin-page">
            <div className="video-background">
                <video autoPlay loop muted>
                    <source src="assets/img/carousel/cargovid5.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="overlay"></div>

            <nav className="top-right-logo">
                <a className="navbar-brand" href="/">
                    <img src="assets/img/logo/logo.png" alt="Logo" />
                </a>
            </nav>

            <div className="signin-content">
                <h2>Sign In</h2>
                <form onSubmit={handleSignIn} className="signin-form">
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <button type="submit" className="btn theme-btn">Sign In</button>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
