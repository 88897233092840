/* eslint-disable react/prop-types */
// src/components/SeaRoutesLayer.jsx

import React, { useMemo } from 'react';
import { Polyline, CircleMarker, Pane } from 'react-leaflet';

const SeaRoutesLayer = ({ tracerEnabled, seaRoutes }) => {
    const crossesAntimeridian = (start, end) => {
        return Math.abs(start[1] - end[1]) > 180;
    };

    const tracerElements = useMemo(() => {
        if (tracerEnabled && seaRoutes) {
            return Object.keys(seaRoutes).map((routeKey) => {
                const positions = seaRoutes[routeKey];
                const segments = [];
                const markers = [];

                if (positions && positions.length > 1) {
                    for (let i = 0; i < positions.length - 1; i++) {
                        const start = positions[i];
                        const end = positions[i + 1];

                        if (crossesAntimeridian(start, end)) {
                            markers.push(start, end);
                        } else {
                            segments.push([start, end]);
                        }
                    }
                }

                return (
                    <React.Fragment key={routeKey}>
                        {segments.map((segment, index) => (
                            <Polyline
                                key={`${routeKey}-segment-${index}`}
                                positions={segment}
                                color="cyan"
                                weight={2}
                                opacity={0.6}
                            />
                        ))}
                        {markers.map((position, index) => (
                            <CircleMarker
                                key={`${routeKey}-marker-${index}`}
                                center={position}
                                radius={3}
                                fillColor="cyan"
                                color="cyan"
                                weight={1}
                                opacity={1}
                                fillOpacity={1}
                            />
                        ))}
                    </React.Fragment>
                );
            });
        }
        return null;
    }, [tracerEnabled, seaRoutes]);

    return <Pane name="tracerPane" style={{ zIndex: 300 }}>{tracerElements}</Pane>;
};

export default SeaRoutesLayer;
