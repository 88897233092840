/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/SolverOverlay.jsx

import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './SolverOverlay.css';
import { FaTools, FaTimes } from 'react-icons/fa';

const SolverOverlay = ({
    numberOfWaitingShips,
    numberOfOccupiedBerths,
    solverMode,
    setSolverMode,
    onAISolve,
    metrics,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOverlay = () => {
        setIsOpen(!isOpen);
    };

    const handleModeChange = (mode) => {
        setSolverMode(mode);
        Swal.fire({
            icon: 'info',
            title: `${mode} Solver Activated`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const handleAISolve = () => {
        if (onAISolve) {
            onAISolve();
            Swal.fire({
                icon: 'success',
                title: 'AI Solver Activated',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    };

    return (
        <>
            <button
                className={`fab-button ${isOpen ? 'open' : ''}`}
                onClick={toggleOverlay}
                aria-label={isOpen ? 'Close Solver Options' : 'Open Solver Options'}
            >
                {isOpen ? <FaTimes /> : <FaTools />}
            </button>

            <div className={`solver-card ${isOpen ? 'open' : ''}`}>
                <div className="solver-card-content">
                    <h2>Port Metrics</h2>
                    <p>Waiting Ships: {numberOfWaitingShips}</p>
                    <p>Occupied Berths: {numberOfOccupiedBerths}</p>

                    <h2>Solvers</h2>
                    <button
                        className={`solver-button solve-manually ${solverMode === 'Manual' ? 'active' : ''
                            }`}
                        onClick={() => handleModeChange('Manual')}
                    >
                        Solve Manually
                    </button>
                    <button
                        className={`solver-button system-suggestion ${solverMode === 'SystemSuggestion' ? 'active' : ''
                            }`}
                        onClick={() => handleModeChange('SystemSuggestion')}
                    >
                        System Suggestion
                    </button>
                    <button
                        className={`solver-button ai-solver ${solverMode === 'AISolver' ? 'active' : ''
                            }`}
                        onClick={handleAISolve}
                    >
                        AI Solver
                    </button>
                </div>
            </div>
        </>
    );
};

export default SolverOverlay;
