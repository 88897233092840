// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB0rXMtAQd0DS8IylgKkCGMBXf38W7DV4g",
    authDomain: "oceanops-bdb87.firebaseapp.com",
    projectId: "oceanops-bdb87",
    storageBucket: "oceanops-bdb87.firebasestorage.app",
    messagingSenderId: "461842776429",
    appId: "1:461842776429:web:f19a5728280d46f60e1a64",
    measurementId: "G-8Y76NLBBYZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
