/* eslint-disable no-unused-vars */
// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './components/SignIn';
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage'; // Import LandingPage

const App = () => {
    const isAuthenticated = () => {
        return localStorage.getItem('token') !== null;
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} /> {/* Add this Route */}
                <Route path="/signin" element={<SignIn />} />
                <Route
                    path="/dashboard"
                    element={isAuthenticated() ? <Dashboard /> : <Navigate to="/signin" />}
                />
            </Routes>
        </Router>
    );
};

export default App;

