/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/FinancialMetrics.jsx

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const FinancialMetrics = ({ selectedPort }) => {
    // Sample data for quarterly revenue
    const revenueData = {
        labels: ['Q1', 'Q2', 'Q3', 'Q4'],
        datasets: [
            {
                label: 'Revenue ($)',
                data: [150000, 200000, 180000, 220000],
                backgroundColor: 'rgba(255, 159, 64, 0.5)',
                borderColor: 'rgba(255, 159, 64, 1)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false, // Allows height to be set via CSS
        plugins: {
            legend: { position: 'top' },
            title: {
                display: true,
                text: 'Quarterly Revenue',
            },
        },
        scales: {
            x: {
                title: { display: true, text: 'Quarter' },
            },
            y: {
                title: { display: true, text: 'Revenue ($)' },
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="financial-metrics">
            <h3>Financial Metrics for {selectedPort}</h3>
            <div className="metrics-charts">
                <div className="chart">
                    <Line data={revenueData} options={lineOptions} />
                </div>
            </div>
        </div>
    );
};

export default FinancialMetrics;
