/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/PortSelector.jsx
import React from 'react';

const PortSelector = ({ onSelectPort }) => {
    const ports = ["New York", "London", "Tokyo", "Sydney", "Dubai", "Mundra"];

    return (
        <div className="port-selector">
            <h4>Select Port</h4>
            <div className="port-list">
                {ports.map(port => (
                    <button key={port} className="port-button" onClick={() => onSelectPort(port)}>
                        {port}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default PortSelector;
