/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/PortMap.jsx

import React, { useState, useRef, useContext } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import FlyToView from './FlyToView';
import TracerToggle from './TracerToggle';
import SolverOverlay from './SolverOverlay';
import BerthsLayer from './BerthsLayer';
import ShipsLayer from './ShipsLayer';
import SeaRoutesLayer from './SeaRoutesLayer';
import BerthAssignmentMenu from './BerthAssignmentMenu';
import { portCoordinates, seaRoutes, portBerths } from '../data/portData';
import { ShipDataContext } from './ShipDataContext';

const PortMap = ({
    selectedPort,
    showAllBerths,
    highlightedBerth,
    highlightedShipId,
}) => {
    const [tracerEnabled, setTracerEnabled] = useState(false);
    const [berthMenu, setBerthMenu] = useState({
        isVisible: false,
        position: { x: 0, y: 0 },
        ship: null,
        berths: [],
    });

    const {
        ships,
        portBerthsStatus,
        solverMode,
        handleSolverModeChange,
        setPortBerthsStatus,
        assignBerthToShip,
    } = useContext(ShipDataContext);

    const mapRef = useRef(null);

    const handleToggleTracer = () => {
        setTracerEnabled((prev) => !prev);
    };

    const handleAssignBerth = (ship, latlng) => {
        if (ship.waitingAtPort && mapRef.current) {
            const port = ship.currentPort;
            const berths = portBerthsStatus[port];
            const availableBerthIndex = berths.findIndex((berth) => berth === null);
            if (availableBerthIndex !== -1) {
                assignBerthToShip(ship, availableBerthIndex);
            } else {
                alert('No available berths at this port.');
            }
        }
    };

    const numberOfWaitingShips = ships.filter(
        (ship) => ship.waitingAtPort && ship.currentPort === selectedPort
    ).length;
    const numberOfOccupiedBerths = portBerthsStatus[selectedPort]
        ? portBerthsStatus[selectedPort].filter((berth) => berth !== null).length
        : 0;

    const center = selectedPort ? portCoordinates[selectedPort] : [0, 0];

    return (
        <div className="port-map-container">
            <div className="map-controls">
                <TracerToggle enabled={tracerEnabled} onToggle={handleToggleTracer} />
            </div>
            <MapContainer
                center={center}
                zoom={10}
                whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {selectedPort && <FlyToView center={center} zoom={10} />}
                <BerthsLayer
                    portBerths={portBerths}
                    selectedPort={selectedPort}
                    showAllBerths={showAllBerths}
                    highlightedBerth={highlightedBerth}
                />
                <ShipsLayer
                    ships={ships}
                    onAssignBerth={handleAssignBerth}
                    highlightedShipId={highlightedShipId} // Pass highlighted ship ID to ShipsLayer
                />
                <SeaRoutesLayer tracerEnabled={tracerEnabled} seaRoutes={seaRoutes} />
            </MapContainer>

            {/* Solver Overlay */}
            <div className="solver-overlay-container">
                <SolverOverlay
                    numberOfWaitingShips={numberOfWaitingShips}
                    numberOfOccupiedBerths={numberOfOccupiedBerths}
                    solverMode={solverMode}
                    setSolverMode={handleSolverModeChange}
                    metrics={{}} // Add metrics as needed
                    onAISolve={() => handleSolverModeChange('AISolver')}
                />
            </div>
        </div>
    );
};

export default PortMap;
