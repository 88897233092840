/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/BerthPlanning.jsx

import React, { useContext } from 'react';
import { ShipDataContext } from './ShipDataContext';
import { portBerths } from '../data/portData';

const BerthPlanning = ({
    selectedPort,
    showAllBerths,
    setShowAllBerths,
    setHighlightedBerth,
}) => {
    const { ships, portBerthsStatus } = useContext(ShipDataContext);

    if (!selectedPort || !portBerths[selectedPort]) {
        return <div className="collapsible-card">No port selected.</div>;
    }

    const berths = portBerths[selectedPort];
    const berthsStatus = portBerthsStatus[selectedPort] || [];

    const handleMouseEnter = (index) => {
        setHighlightedBerth(index);
    };

    const handleMouseLeave = () => {
        setHighlightedBerth(null);
    };

    return (
        <div className="collapsible-card">
            <h3>Berth Planning</h3>
            <label>
                <input
                    type="checkbox"
                    checked={showAllBerths}
                    onChange={() => setShowAllBerths(!showAllBerths)}
                />{' '}
                Show All Berths
            </label>
            <table className="berth-planning-table">
                <thead>
                    <tr>
                        <th>Berth Name</th>
                        <th>Status</th>
                        <th>Ship ID</th>
                        <th>Progress</th>
                    </tr>
                </thead>
                <tbody>
                    {berths.map((berth, index) => {
                        const { name } = berth;
                        const shipId = berthsStatus[index];
                        const ship = ships.find((s) => s.id === shipId);

                        return (
                            <tr
                                key={index}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <td title={name}>
                                    {name.length > 15
                                        ? `${name.slice(0, 15)}...`
                                        : name}
                                </td>
                                <td>
                                    <span
                                        className={`status ${shipId ? 'occupied' : 'available'}`}
                                    >
                                        {shipId ? 'Occupied' : 'Available'}
                                    </span>
                                </td>
                                <td>{shipId || '--'}</td>
                                <td>
                                    {ship && ship.isDocked && (
                                        <div className="progress-bar">
                                            <div
                                                className="progress-fill"
                                                style={{ width: `${ship.dockingProgress}%` }}
                                            ></div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <style>{`
                .progress-bar {
                    width: 100px;
                    height: 10px;
                    background-color: #e0e0e0;
                    border-radius: 5px;
                    overflow: hidden;
                }
                .progress-fill {
                    height: 100%;
                    background-color: #4caf50;
                    transition: width 0.6s ease;
                }
            `}</style>
        </div>
    );
};

export default BerthPlanning;
