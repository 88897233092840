// src/index.jsx
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';   // Import the main app

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
