/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/NationalShipsTracking.jsx

import React, { useContext } from 'react';
import { ShipDataContext } from './ShipDataContext';
import { portCountries } from '../data/portData';

const NationalShipsTracking = ({ selectedPort }) => {
    const { ships } = useContext(ShipDataContext);

    const countryCode = portCountries[selectedPort];

    if (!countryCode) {
        return <div className="national-ships-tracking">No port or country selected.</div>;
    }

    const nationalShips = ships.filter((ship) => ship.metadata.country === countryCode);

    return (
        <div className="national-ships-tracking">
            <h3>National Ships Tracking: {countryCode}</h3>
            <div className="scrollable-table">
                <table>
                    <thead>
                        <tr>
                            <th>Ship ID</th>
                            <th>Type</th>
                            <th>Cargo Type</th>
                            <th>Cargo Value</th>
                            <th>Length (m)</th>
                            <th>Depth (m)</th>
                            <th>Status</th>
                            <th>Current Port</th>
                            <th>Destination</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nationalShips.map((ship) => (
                            <tr key={ship.id}>
                                <td>{ship.id}</td>
                                <td>{ship.metadata.type}</td>
                                <td>{ship.metadata.commodity || ship.metadata.type}</td>
                                <td>${ship.metadata.cargoValue}</td>
                                <td>{ship.metadata.length}</td>
                                <td>{ship.metadata.depth}</td>
                                <td>{ship.isDocked ? 'Docked' : ship.waitingAtPort ? 'Waiting' : 'On Route'}</td>
                                <td>{ship.currentPort || 'At Sea'}</td>
                                <td>{ship.destinationPort || 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default NationalShipsTracking;
