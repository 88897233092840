/* eslint-disable no-unused-vars */
// src/components/LandingPage.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loader timeout
    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust loading time as needed
        return () => clearTimeout(timer);
    }, []);

    const handleGetStarted = () => {
        navigate('/signin');
    };

    return (
        <div className="landing-page">
            
            {/* Preloader */}
            {isLoading && (
                <div className="preloader">
                    <div className="loader">
                        <div className="ytp-spinner">
                            <div className="ytp-spinner-container">
                                <div className="ytp-spinner-rotator">
                                    <div className="ytp-spinner-left">
                                        <div className="ytp-spinner-circle"></div>
                                    </div>
                                    <div className="ytp-spinner-right">
                                        <div className="ytp-spinner-circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Header with Navbar */}
            {!isLoading && (
                <>
                <header className="header navbar-area bg-white">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand" href="#">
                                        <img src="/assets/img/logo/logo.png" alt="Logo" />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul id="nav" className="navbar-nav ml-auto">
                                            <li className="nav-item"><a className="page-scroll active" href="#home">Home</a></li>
                                            <li className="nav-item"><a className="page-scroll" href="#about">About</a></li>
                                            <li className="nav-item"><a className="page-scroll" href="#services">Services</a></li>
                                            <li className="nav-item"><a className="page-scroll" href="#portfolio">Portfolio</a></li>
                                            <li className="nav-item"><a className="page-scroll" href="#team">Team</a></li>
                                            <li className="nav-item"><a className="page-scroll" href="#contact">Contact</a></li>
                                        </ul>
                                        <div className="header-btn">
                                            <button onClick={handleGetStarted} className="theme-btn">Get Started</button>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
            
                
                <section id="home" className="carousel-section-wrapper">
                    <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-ride="carousel">
                        <div className="carousel-inner">
                            
                            <div className="carousel-section carousel-item active clip-bg pt-225 pb-200 img-bg" >
                                    <video autoPlay loop muted playsInline className="carousel-video">
                                        <source src="assets/img/carousel/cargovid2.mp4" type="video/mp4" />
                                    </video>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-8 col-lg-10 mx-auto">
                                            <div className="carousel-content text-center">
                                                <div className="section-title">
                                                        <h2 className="text-white">Welcome to OceanOps: </h2>
                                                        <p className="text-white">Efficient vessel traffic and berth management for smooth port operations.</p>
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>

                { /* feature-section start */}
                    <section id="about" className="pt-100">
                        <div className="about-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="about-img-wrapper">
                                            <div className="about-img position-relative d-inline-block wow fadeInLeft" data-wow-delay=".3s">
                                                <img src="assets/img/about/about-img.png" alt="" />
                                                    <div className="about-experience">
                                                        <h3>Simplify your vessel management.</h3>
                                                        <p>
                                                            Our platform streamlines berth allocation and traffic coordination, helping to minimize delays. Explore our user-friendly dashboard to track vessel schedules and port capacities along major shipping routes.</p>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="about-content-wrapper">
                                            <div className="section-title">
                                                <span className="wow fadeInUp" data-wow-delay=".2s">About Us</span>
                                                <h2 className="mb-40 wow fadeInRight" data-wow-delay=".4s">Innovating Maritime Vessel Management for shipping ports.</h2>
                                            </div>
                                            <div className="about-content">
                                                <p className="mb-45 wow fadeInUp" data-wow-delay=".6s">

                                                    At OceanOps, we are committed to revolutionizing vessel management and port operations. Our intuitive platform leverages advanced analytics and AI to empower port authorities and shipping companies in optimizing berth allocation and streamlining vessel traffic in real time.<br className="d-none d-md-block d-lg-none d-xl-block" />
                                                    Our team brings extensive experience in remote sensing and geoinformatics, utilizing cutting-edge technologies to analyze spatial data critical for optimizing maritime operations.
                                                        Paired with our CTO, an IIT Kharagpur graduate and Data Scientist, we harness the power of AI and machine learning to transform complex maritime data into actionable insights.
                                                             Together, our complementary skills enable OceanOps to effectively tackle the challenges in maritime logistics, driving efficiency and sustainability in the shipping industry.</p>
                                                            <div className="counter-up wow fadeInUp" data-wow-delay=".5s">
                                                                <div className="counter">

                                                                    <h4></h4>
                                                                    <p> <br className="d-none d-md-block d-lg-none d-xl-block" /> </p>
                                                                </div>
                                                                <div className="counter">

                                                                    <p> <br className="d-none d-md-block d-lg-none d-xl-block" /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                { /* service-section */}
                    <section id="services" className="service-section pt-130">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-7 col-md-9 mx-auto">
                                    <div className="section-title text-center mb-55">
                                        <span className="wow fadeInDown" data-wow-delay=".2s">Services</span>
                                        <h2 className="wow fadeInUp" data-wow-delay=".4s">At OceanOps
                                        </h2>
                                        <p className="wow fadeInUp" data-wow-delay=".6s"> We aim to transform the maritime industry with innovative solutions for efficient vessel management and port operations.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-box box-style">
                                        <div className="service-icon box-icon-style">
                                            <i className="lni lni-leaf"></i>
                                        </div>
                                        <div className="box-content-style service-content">
                                            <h4> Efficiency</h4>
                                            <p>

                                                Replace outdated methods with our streamlined vessel management platform.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-box box-style">
                                        <div className="service-icon box-icon-style">
                                            <i className="lni lni-bootstrap"></i>
                                        </div>
                                        <div className="box-content-style service-content">
                                            <h4>Access</h4>
                                            <p>
                                                Integrate real-time maritime data for effective scheduling and berth management.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-box box-style">
                                        <div className="service-icon box-icon-style">
                                            <i className="lni lni-briefcase"></i>
                                        </div>
                                        <div className="box-content-style service-content">
                                            <h4>Intelligence</h4>
                                            <p>
                                                Leverage AI-driven analytics to optimize resource allocation and minimize delays.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-box box-style">
                                        <div className="service-icon box-icon-style">
                                            <i className="lni lni-bolt"></i>
                                        </div>
                                        <div className="box-content-style service-content">
                                            <h4>Management</h4>
                                            <p>
                                                Enhance visibility to stabilize berth assignments and improve turnaround times.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-box box-style">
                                        <div className="service-icon box-icon-style">
                                            <i className="lni lni-infinite"></i>
                                        </div>
                                        <div className="box-content-style service-content">
                                            <h4>Collaboration</h4>
                                            <p>
                                                Keep stakeholders informed with real-time updates and alerts.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-box box-style">
                                        <div className="service-icon box-icon-style">
                                            <i className="lni lni-reload"></i>
                                        </div>
                                        <div className="box-content-style service-content">
                                            <h4>Insights</h4>
                                            <p>
                                                Monitor key metrics with dashboards to identify bottlenecks and improve operations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                { /* portfolio-section */}
                <section id="portfolio" className="portfolio-section pt-130">
                    <section id="team" className="contact-section cta-bg img-bg pt-110 pb-100" style={{ backgroundImage: "url('assets/img/bg/cta-bg.jpg')" }} >
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-5 col-lg-7">
                                        <div className="section-title mb-60">
                                            {/*<span className="text-white wow fadeInDown" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInDown" }}>*/}
                                            {/*    Welcome*/}
                                            {/*</span>*/}
                                            <h2 className="text-white wow fadeInUp" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInUp" }}>
                                                Experience the Power of OceanOps
                                            </h2>
                                            <p className="text-white wow fadeInUp" data-wow-delay=".6s" style={{ visibility: "visible", animationDelay: "0.6s", animationName: "fadeInUp" }}>
                                                Discover the full OceanOps platform for complete port and vessel management. Contact us to get started.
                                            </p>
                                        </div>


                                </div>
                                <div className="col-xl-7 col-lg-5">
                                    <div className="contact-btn text-left text-lg-right">
                                        <a href="https://rebrand.ly/fancy-ud" rel="nofollow" className="theme-btn">Purchase Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                { /* process-section */}
                <section id="process" className="process-section pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-7 col-md-9 mx-auto">
                                <div className="section-title text-center mb-55">
                                    <span className="wow fadeInDown" data-wow-delay=".2s">Process</span>
                                    <h2 className="wow fadeInUp" data-wow-delay=".4s">Working Process</h2>
                                        <p className="wow fadeInUp" data-wow-delay=".6s">A Seamless Journey from Concept to Implementation</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center time-line">
                            <div className="col-12">
                                <div className="single-timeline">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 order-last order-lg-first">
                                            <div className="timeline-content left-content text-lg-right">
                                                <div className="box-icon-style">
                                                    <i className="lni lni-search-alt"></i>
                                                </div>
                                                    <h4 className="mb-10">Assess</h4>
                                                    <p>
                                                        Evaluate relevant data to understand your operational needs and challenges.</p>

                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-5">
                                            <div className="timeline-img">
                                                <img src="assets/img/timeline/timeline-1.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="single-timeline">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5">
                                            <div className="timeline-img">
                                                <img src="assets/img/timeline/timeline-2.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-5">
                                            <div className="timeline-content right-content text-left">
                                                <div className="box-icon-style">
                                                    <i className="lni lni-layers"></i>
                                                </div>
                                                    <h4 className="mb-10">Pilot</h4>
                                                    <p>Develop a proof of concept (POC) to demonstrate the effectiveness of our solution.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="single-timeline">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 order-last order-lg-first">
                                            <div className="timeline-content left-content text-lg-right">
                                                <div className="box-icon-style">
                                                    <i className="lni lni-code-alt"></i>
                                                </div>
                                                    <h4 className="mb-10">Implement</h4>
                                                    <p>
                                                        Integrate the solution into your systems and train your team for effective use.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-5">
                                            <div className="timeline-img">
                                                <img src="assets/img/timeline/timeline-3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="single-timeline">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5">
                                            <div className="timeline-img">
                                                <img src="assets/img/timeline/timeline-4.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-5">
                                            <div className="timeline-content right-content text-left">
                                                <div className="box-icon-style">
                                                    <i className="lni lni-rocket"></i>
                                                    </div>
                                                    <h4 className="mb-10">Support</h4>
                                                    <p>
                                                        Provide ongoing support and maintenance to ensure optimal performance.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                { /* contact-section */}
                <section className="contact-section pt-130 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="contact-item-wrapper">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-12">
                                            <div className="contact-item">
                                                <div className="contact-icon">
                                                    <i className="lni lni-phone"></i>
                                                </div>
                                                <div className="contact-content">
                                                    <h4>Contact</h4>
                                                        <p>91-7548082621</p>
                                                        <p>ind.baman@gmail.com</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-12">
                                            <div className="contact-item">
                                                <div className="contact-icon">
                                                    <i className="lni lni-map-marker"></i>
                                                </div>
                                                <div className="contact-content">
                                                    <h4>Address</h4>
                                                        <p>400001, Mumbai</p>
                                                        <p>India</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-12">
                                            <div className="contact-item">
                                                <div className="contact-icon">
                                                    <i className="lni lni-alarm-clock"></i>
                                                </div>
                                                <div className="contact-content">
                                                    <h4>Shedule</h4>
                                                    <p>24 Hours / 7 Days Open </p>
                                                    <p>Office time: 10 AM - 5:30 PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="contact-form-wrapper">
                                    <div className="row">
                                        <div className="col-xl-10 col-lg-8 mx-auto">
                                            <div className="section-title text-center mb-50">
                                                <span className="wow fadeInDown" data-wow-delay=".2s">Get in Touch</span>
                                                <h2 className="wow fadeInUp" data-wow-delay=".4s">Ready to Get Started</h2>
                                                    <p className="wow fadeInUp" data-wow-delay=".6s">We look forward to collaborating with you to elevate your maritime operations and achieve lasting success together.</p>
                                                </div>

                                        </div>
                                    </div>
                                    <form action="assets/php/mail.php" className="contact-form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" name="name" id="name" placeholder="Name" required />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="email" name="email" id="email" placeholder="Email" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" name="phone" id="phone" placeholder="Phone" required />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" name="subject" id="email" placeholder="Subject" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea name="message" id="message" placeholder="Type Message" rows="5"></textarea>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="button text-center">
                                                    <button type="submit" className="theme-btn">Send Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                { /* subscribe-section */ }
                <section className="subscribe-section pt-70 pb-70 img-bg" style={{ backgroundImage: "url('assets/img/bg/common-bg.svg')" }} >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6">
                                <div className="section-title mb-30">
                                    <span className="text-white wow fadeInDown" data-wow-delay=".2s">Subscribe</span>
                                    <h2 className="text-white mb-40 wow fadeInUp" data-wow-delay=".4s">Subscribe Our Newsletter</h2>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <form action="#" className="subscribe-form wow fadeInRight" data-wow-delay=".4s">
                                    <input type="text" name="subs-email" id="subs-email" placeholder="Your Email" />
                                        <button type="submit"><i className="lni lni-telegram-original"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            
                <a href="#" className="scroll-top">
                    <i className="lni lni-arrow-up"></i>
                    </a>
                </>
            )}
        </div>
    );
};

export default LandingPage;
