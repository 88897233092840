/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/BusinessMetrics.jsx

import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const BusinessMetrics = ({ selectedPort }) => {
    // Sample data for charts
    const berthUtilizationData = {
        labels: ['Berth 1', 'Berth 2', 'Berth 3', 'Berth 4'],
        datasets: [
            {
                label: 'Oil',
                data: [30, 50, 20, 40],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Coal',
                data: [20, 30, 50, 35],
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            // Add more datasets for other ship types if needed
        ],
    };

    const cargoThroughputData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                label: 'Cargo Throughput (tons)',
                data: [5000, 7000, 6000, 8000, 7500, 9000],
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false, // Allows height to be set via CSS
        plugins: {
            legend: { position: 'top' },
            title: {
                display: true,
                text: 'Berth Utilization Rate by Ship Type',
            },
        },
        scales: {
            x: {
                title: { display: true, text: 'Berth' },
            },
            y: {
                title: { display: true, text: 'Utilization (%)' },
                beginAtZero: true,
                max: 100,
            },
        },
    };

    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false, // Allows height to be set via CSS
        plugins: {
            legend: { position: 'top' },
            title: {
                display: true,
                text: 'Cargo Throughput Over Time',
            },
        },
        scales: {
            x: {
                title: { display: true, text: 'Month' },
            },
            y: {
                title: { display: true, text: 'Throughput (tons)' },
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="business-metrics">
            <h3>Business Metrics for {selectedPort}</h3>
            <div className="metrics-charts">
                <div className="chart">
                    <Bar data={berthUtilizationData} options={barOptions} />
                </div>
                <div className="chart">
                    <Line data={cargoThroughputData} options={lineOptions} />
                </div>
            </div>
        </div>
    );
};

export default BusinessMetrics;
