// src/components/Ship.js

import { portCountries } from '../data/portData';
import { calculateNauticalMiles } from '../utils/nautical';
import { generateGreatCirclePath } from '../utils/greatCircle';
import { US, GB, IN, AU, JP, AE } from 'country-flag-icons/react/3x2'; // Import specific flags

// Define ranges for randomized ship properties
const shipTypeConfigs = {
    Oil: { lengthRange: [290, 310], depthRange: [18, 22], cargoCapacityRange: [95000, 105000], penaltyRate: 2000, businessValueRange: [4800000, 5200000] },
    Coal: { lengthRange: [240, 260], depthRange: [17, 19], cargoCapacityRange: [75000, 85000], penaltyRate: 1500, businessValueRange: [2800000, 3200000] },
    Minerals: { lengthRange: [190, 210], depthRange: [14, 16], cargoCapacityRange: [45000, 55000], penaltyRate: 1000, businessValueRange: [2300000, 2700000] },
    Chemicals: { lengthRange: [140, 160], depthRange: [11, 13], cargoCapacityRange: [38000, 42000], penaltyRate: 1800, businessValueRange: [3300000, 3700000] },
    Cargo: { lengthRange: [90, 110], depthRange: [9, 11], cargoCapacityRange: [18000, 22000], penaltyRate: 800, businessValueRange: [1400000, 1600000] }
};

const generalCargoTypes = ['Textiles', 'Perishables', 'Electronics', 'Automobiles', 'Furniture'];

function getRandomInRange([min, max]) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Map of port codes to flag components
const flagComponents = {
    US: US,
    GB: GB,
    IN: IN,
    AU: AU,
    JP: JP,
    AE: AE,
};

class Ship {
    constructor(id, startPort, portCoordinates, seaRoutes, shipType = null) {
        this.id = id;
        this.currentPort = startPort;
        this.position = portCoordinates[startPort] || null;
        this.isDocked = false;
        this.waitingAtPort = true;
        this.dockingStartTime = null;
        this.path = [];
        this.startTime = null;
        this.duration = 3000;
        this.portCoordinates = portCoordinates;
        this.seaRoutes = seaRoutes;
        this.metadata = this.generateShipMetadata(shipType || this.assignRandomShipType());
        this.metadata.source = this.currentPort; // Initialize source as the start port
    }

    assignRandomShipType() {
        const random = Math.random() * 100;
        if (random < 60) return 'Cargo'; // 60%
        if (random < 70) return 'Oil';          // 10%
        if (random < 80) return 'Coal';         // 10%
        if (random < 90) return 'Minerals';     // 10%
        return 'Chemicals';                     // 10%
    }

    generateShipMetadata(type) {
        const config = shipTypeConfigs[type];
        const commodity = type === 'Cargo' ? generalCargoTypes[Math.floor(Math.random() * generalCargoTypes.length)] : null;

        const countryCode = portCountries[this.currentPort] || 'US'; // Default to 'US'
        const FlagIcon = flagComponents[countryCode] || flagComponents['US']; // Default flag if not found

        return {
            type,
            length: getRandomInRange(config.lengthRange),
            depth: getRandomInRange(config.depthRange),
            cargoCapacity: getRandomInRange(config.cargoCapacityRange),
            penaltyRate: config.penaltyRate,
            businessValue: getRandomInRange(config.businessValueRange),
            commodity,
            cargoValue: getRandomInRange(config.businessValueRange),
            source: this.currentPort,  // Initial source port
            destination: this.destinationPort,  // Set as null initially
            country: countryCode,
            flag: FlagIcon, // Store flag component directly
        };
    }

    setDestination(newPort) {
        try {
            if (!newPort || !this.portCoordinates[newPort]) {
                console.error(`Invalid destination: ${newPort}`);
                return;
            }

            if (this.currentPort === newPort) {
                console.warn(`Ship ${this.id} is already at ${newPort}. No movement needed.`);
                this.isDocked = true;
                return;
            }

            // Update previous source as the last docked port and destination as the new port
            this.metadata.source = this.currentPort;
            this.destinationPort = newPort;
            this.metadata.destination = newPort;

            this.destination = this.portCoordinates[newPort];
            this.isDocked = false;
            this.waitingAtPort = false;
            this.dockingStartTime = null;
            this.startTime = Date.now();
            this.pathIndex = 0;

            const seaRoute = this.getSeaRoute(this.currentPort, newPort);
            if (seaRoute && seaRoute.length > 0) {
                this.path = seaRoute;
            } else {
                console.error(`No valid sea route found for Ship ${this.id} from ${this.currentPort} to ${newPort}`);
                this.isDocked = true;
                this.path = [];
                return;
            }

            const distance = calculateNauticalMiles(this.position[0], this.position[1], this.destination[0], this.destination[1]);
            this.duration = Math.max(3000, (distance / 50) * 5000);
        } catch (error) {
            console.error(`Error setting destination for Ship ${this.id}:`, error);
        }
    }

    getSeaRoute(startPort, destinationPort) {
        const routeKey = `${startPort}-${destinationPort}`;
        const reverseRouteKey = `${destinationPort}-${startPort}`;

        if (this.seaRoutes[routeKey]) {
            return this.seaRoutes[routeKey];
        } else if (this.seaRoutes[reverseRouteKey]) {
            return [...this.seaRoutes[reverseRouteKey]].reverse();
        } else {
            const startCoords = this.portCoordinates[startPort];
            const destCoords = this.portCoordinates[destinationPort];
            if (startCoords && destCoords) {
                const result = generateGreatCirclePath(startCoords, destCoords, 100);
                return result.path;
            } else {
                console.error(`Coordinates not found for ports ${startPort} or ${destinationPort}.`);
                return null;
            }
        }
    }

    updatePositionTowards(targetPosition) {
        if (!targetPosition) return;
        const speed = this.speed || 0.0005; // Adjust speed as needed
        const [lat1, lon1] = this.position;
        const [lat2, lon2] = targetPosition;
        const deltaLat = lat2 - lat1;
        const deltaLon = lon2 - lon1;
        const distance = Math.sqrt(deltaLat * deltaLat + deltaLon * deltaLon);
        if (distance === 0) return;
        const ratio = speed / distance;
        const newLat = lat1 + deltaLat * ratio;
        const newLon = lon1 + deltaLon * ratio;
        this.position = [newLat, newLon];
    }

    updatePosition() {
        try {
            if (this.path && this.path.length > 1 && !this.isDocked) {
                const elapsed = Date.now() - this.startTime;
                const totalDuration = this.duration;
                const t = Math.min(elapsed / totalDuration, 1);
                const totalSegments = this.path.length - 1;
                const progress = t * totalSegments;
                const currentSegment = Math.floor(progress);
                const segmentT = progress - currentSegment;

                if (currentSegment >= totalSegments) {
                    this.position = this.destination;
                    this.isDocked = true;
                    this.waitingAtPort = true;
                    this.dockingStartTime = Date.now();
                    this.currentPort = this.destinationPort;
                    this.destinationPort = null;
                    return;
                }

                const startCoord = this.path[currentSegment];
                const endCoord = this.path[currentSegment + 1];

                if (startCoord && endCoord) {
                    let startLon = startCoord[1];
                    let endLon = endCoord[1];
                    const deltaLon = endLon - startLon;

                    if (deltaLon > 180) startLon += 360;
                    else if (deltaLon < -180) endLon += 360;

                    const lat = startCoord[0] + (endCoord[0] - startCoord[0]) * segmentT;
                    let lon = startLon + (endLon - startLon) * segmentT;

                    lon = ((lon + 180) % 360) - 180;
                    this.position = [lat, lon];
                }
            }
        } catch (error) {
            console.error(`Error updating position for Ship ${this.id}:`, error);
        }
    }
}

export default Ship;
