/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/OperationalMetrics.jsx

import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const OperationalMetrics = ({ selectedPort }) => {
    // Sample data for ship turnaround times
    const shipTurnaroundData = {
        labels: ['<24h', '24-48h', '>48h'],
        datasets: [
            {
                label: 'Turnaround Time',
                data: [40, 35, 25],
                backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
                hoverOffset: 4,
            },
        ],
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false, // Allows height to be set via CSS
        plugins: {
            legend: { position: 'top' },
            title: {
                display: true,
                text: 'Ship Turnaround Times',
            },
        },
    };

    return (
        <div className="operational-metrics">
            <h3>Operational Metrics for {selectedPort}</h3>
            <div className="metrics-charts">
                <div className="chart">
                    <Pie data={shipTurnaroundData} options={pieOptions} />
                </div>
            </div>
        </div>
    );
};

export default OperationalMetrics;
