/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/NavBar.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCog, FaSignOutAlt } from 'react-icons/fa';

const NavBar = ({ onSettingsClick, handleLogout }) => {
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);

    const handleScroll = () => {
        setIsNavbarCollapsed(window.scrollY > 50);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <nav className={`dashboard-navbar ${isNavbarCollapsed ? 'shrink' : ''}`}>
            <div className="navbar-left">
                <Link to="/">
                    <img src="assets/img/logo/logo.png" alt="Logo" className="dashboard-logo" />
                </Link>
            </div>
            <div className="navbar-right">
                <button onClick={onSettingsClick} className="navbar-btn">
                    <FaCog /> Settings
                </button>
                <button onClick={handleLogout} className="navbar-btn">
                    <FaSignOutAlt /> Logout
                </button>
            </div>
        </nav>
    );
};

export default NavBar;
