/* eslint-disable react/prop-types */
// src/components/FlyToView.jsx
import { useMap } from 'react-leaflet';
import { useEffect } from 'react';

const FlyToView = ({ center, zoom }) => {
    const map = useMap();

    useEffect(() => {
        if (center && map) {
            map.flyTo(center, zoom, {
                animate: true,
                duration: 2, // Adjust the duration for speed
            });
        }
    }, [center, zoom, map]);

    return null;
};

export default FlyToView;
