/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

const TracerToggle = ({ enabled, onToggle }) => (
    <div>
        <label>
            Show Tracer
            <input type="checkbox" checked={enabled} onChange={onToggle} />
        </label>
    </div>
);

export default TracerToggle;
